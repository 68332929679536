import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//用户查询
export const userInfo = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/info",
  });

//线下充值
export const rechargePay = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/ledger/recharge/pay",
  });

//充值流水
export const getRechargeList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/ledger/recharge/list",
  });

//充值流水导出
export const exportRechargeList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/ledger/recharge/list`,
  });

//获取用户充值数据统计列表
export const getUserPayStatisticsList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user_pay_statistics/list",
  });

//导出用户充值数据统计列表
export const exportUserPayStatisticsList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user_pay_statistics/export`,
  });

//AppsFlyer充值列表
export const getAppsFlyerRechargeList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/ledger/appsflyer/recharge/list",
  });

//导出AppsFlyer充值列表
export const exportAppsFlyerRechargeList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/ledger/appsflyer/recharge/export`,
  });
