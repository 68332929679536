
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { userInfo, rechargePay } from "@/api/request/recharge";

//组件
@Component({
  name: "RechargePay",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private propList: any[] = [];
  private checked: boolean = false;
  private listLoading: boolean = true;

  //表单
  private form: any = {
    nickName: "",
    is_discount: 0,
    num: undefined,
    money: undefined,
    userid: undefined,
    ext_num: undefined,
  };

  //规则
  private rules: any = {
    nickName: [{ required: true, message: "请获取用户昵称", trigger: "blur" }],
    userid: [{ required: true, message: "请输入用户ID", trigger: "blur" }],
    money: [{ required: true, message: "请输入充值金额", trigger: "blur" }],
    num: [{ required: true, message: "请输入充值钻石数量", trigger: "blur" }],
    ext_num: [{ required: true, message: "请输入额外赠送钻石数量", trigger: "blur" }],
  };

  //请求数据
  private listQuery: any = {
    //页码数据
    row: 20,
    page: 1,
  };

  //重置表单
  private resetForm() {
    //数据赋值
    this.checked = false;

    //重置表单
    this.form = {
      nickName: "",
      is_discount: 0,
      num: undefined,
      money: undefined,
      userid: undefined,
      ext_num: undefined,
    };
  }

  //处理确定
  private handleSubmit(): void {
    //获取规则
    const ruleForm: any = this.$refs["ruleForm"];

    //验证规则
    ruleForm.validate((valid: any) => {
      if (valid) {
        //显示提示
        this.$confirm(`确定要给 ID${this.form.userid} 昵称：${this.form.nickName}  的用户充值${this.form.money} RM  钻石  ${this.form.num + this.form.ext_num} 吗？`, "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            //数据赋值
            this.form.is_discount = this.checked ? 1 : 0;

            //请求数据
            const res = await rechargePay(this.form);

            //请求成功
            if (res.status === 0) {
              //显示提示
              this.$message({ type: "success", message: "充值成功!" });

              //重置表单
              this.resetForm();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        return false;
      }
    });
  }

  //处理查询
  private async searchHandle() {
    //验证请求
    const res = await userInfo({ userid: this.form.userid });

    //验证成功
    if (res.status === 0) {
      //数据赋值
      this.form.nickName = res.data.nickname;

      //显示提示
      this.$message.success("获取成功");
    }
  }
}
